
.event-avatar {
  width: 14.8rem;
  height: 20.4rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.chosen-container {
  width: calc(100% - 23.2rem)!important;
}
